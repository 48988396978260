import manhattanLogo from "./../public/img/manh-logo.jpg";

export default [
  {
    id: 1,
    title: "PHI Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa3.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 2,
    title: "DAL Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa2.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 3,
    title: "ABQ Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa2.niagarawater.com:22000/",
    src: manhattanLogo,
  },
  {
    id: 4,
    title: "CON Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa3.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 5,
    title: "DA2 Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa2.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 6,
    title: "GRV Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa1.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 7,
    title: "NDC Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa4.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 8,
    title: "HAM Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa2.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 9,
    title: "MOR Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa1.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 10,
    title: "ALN Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa1.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 11,
    title: "DEN Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa1.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 12,
    title: "PHX Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa2.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 13,
    title: "STK Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa3.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 14,
    title: "ST2 Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa3.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 15,
    title: "PHI DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa3.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 17,
    title: "CON DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa3.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 18,
    title: "DAL DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa2.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 19,
    title: "DA2 DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa2.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 20,
    title: "GRV DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa1.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 21,
    title: "NDC DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa4.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 22,
    title: "HAM DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa2.niagarawater.com:10400/",
    src: manhattanLogo,
  },
  {
    id: 23,
    title: "MOR DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa1.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 24,
    title: "ALN DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa1.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 25,
    title: "DEN DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa1.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 26,
    title: "PHX DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa2.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 28,
    title: "CON UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa3.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 29,
    title: "DAL UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa2.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 30,
    title: "DA2 UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa2.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 31,
    title: "PHI UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa3.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 32,
    title: "GRV UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa1.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 33,
    title: "NDC UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa4.niagarawater.com:12000/",
    src: manhattanLogo,
  },
  {
    id: 34,
    title: "HAM UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa2.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 35,
    title: "MOR UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa1.niagarawater.com:12100/",
    src: manhattanLogo,
  },
  {
    id: 36,
    title: "DEN UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa1.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 37,
    title: "ALN UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa1.niagarawater.com:12400/",
    src: manhattanLogo,
  },
  {
    id: 38,
    title: "PHX UAT",
    alt: "Manhattan Logo",
    url: "http://manu1xa2.niagarawater.com:12500/",
    src: manhattanLogo,
  },
  {
    id: 39,
    title: "MIL Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa5.niagarawater.com:12000",
    src: manhattanLogo,
  },
  {
    id: 40,
    title: "STK DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa3.niagarawater.com:12400",
    src: manhattanLogo,
  },
  {
    id: 31,
    title: "ST2 DEV",
    alt: "Manhattan Logo",
    url: "http://mand2xa3.niagarawater.com:12500",
    src: manhattanLogo,
  },
  {
    id: 32,
    title: "TEM Prod",
    alt: "Manhattan Logo",
    url: "http://manp1xa6.niagarawater.com:12000/",
    src: manhattanLogo,
  },
];
